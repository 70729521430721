@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

html {
  scroll-behavior: smooth;
}

html body {
  overflow-x: hidden;
}

body {
  background: url(../../img/fotoFundo2.png), #121212;
  background-repeat: no-repeat;
  overflow-x: none;
}