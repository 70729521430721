#projetos {
   margin-bottom: 150px;
   width: 100%;
   padding: 0 10%;
}

.projetosGrid {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   row-gap: 60px;
   justify-items: center;
   align-items: center;
}

@media screen and (max-width: 768px) {
   .projetosGrid {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
   }
}