footer {
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 100;
  justify-content: space-between;
  
  padding: 0 10%;
  height: 150px;

  border-top: 2px solid #2E2E2E;
  background: #171717;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  a {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    color: #979797;
  }
}

@media screen and (max-width: 768px) {
  footer {
    justify-content: center;
  }

  footer a {
    display: none;
  }
}